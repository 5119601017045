<script lang="ts" setup>
import { onClickOutside, useFocus, useMagicKeys } from "@vueuse/core";
defineEmits<{
  (e: "close"): void;
}>();

withDefaults(
  defineProps<{
    showStoreFlyout?: boolean;
  }>(),
  { showStoreFlyout: false },
);

const { searchTerm, search, getProducts, getTotal, loading } = useProductSearchSuggest();

// Reference to the search container
const searchContainer = ref(null);
const searchInput = ref();

// String the user has typed in the search field
const typingQuery = ref("");

watch(typingQuery, (value) => {
  if (value.length >= 3) {
    performSuggestSearch(value);
  }
});

// Defer the search request to prevent the search from being triggered too after typing
const performSuggestSearch = useDebounceFn((value) => {
  searchTerm.value = value;
  search();
}, 300);

const { enter } = useMagicKeys({ target: searchInput });
const { push } = useRouter();

const isSideMenuOpened = inject("isSideMenuOpened", ref(false));

watch(enter, (value) => {
  if (!value) return;

  isSideMenuOpened.value = false;

  push("/search?query=" + typingQuery.value);
});
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <ClientOnly>
    <SharedFormInput
        v-model="typingQuery"
        data-testid="layout-search-input"
        placeholder="Produkte Suchen"
        :focus="true"
    />

    <div class="mt-8">
      <div v-if="loading" class="flex justify-center items-center min-h-[50vh]">
        <span class="spinner"></span>
      </div>
      <div v-else>
        <SharedFeed :products="getProducts.slice(0, 12)" type="stacked" size="small" @close="$emit('close')" />
        <SharedFormButton
          v-if="getTotal > 0"
          :to="`/search?query=${typingQuery}`"
          layout="secondaryDark"
          size="default"
          type="link"
          class="mt-8 w-full"
          @click="$emit('close')"
        >
          Alle {{ getTotal }}
          <span v-if="getTotal !== 1">Ergebnisse</span>
          <span v-if="getTotal == 1">Ergebnis</span> anzeigen
        </SharedFormButton>
        <div
          v-else
          class="flex justify-center items-center"
          :class="{
            'min-h-[50vh]': searchTerm.length >= 3,
          }"
        >
          <template v-if="searchTerm.length >= 3">
            <div class="text-center">
              <p class="text-p">Keine Ergebnisse für <span class="font-bold">{{ searchTerm }}</span></p>
            </div>
          </template>
          <template v-else>

          </template>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>
